/* src/App.css */

/* Body Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Dancing Script', sans-serif; /* Default font */
  overflow-x: hidden;
  background: linear-gradient(135deg, #ece9e6, #ffffff);
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

/* Adjust main to account for fixed navbar */
main {
  margin-top: 70px; /* Adjusted to match navbar height */
}

/* Logo Section */
.logo-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0; /* Reduced padding */
  overflow: hidden;
}

.logo-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, transparent, rgba(0, 0, 0, 0.6));
  z-index: 1;
}

.logo-container {
  position: relative;
  z-index: 2;
}

.main-logo {
  width: 60%;
  max-width: 400px; /* Reduced max-width */
  height: auto;
}

.main-header {
  margin-top: 20px; /* Spacing below the logo */
}

.main-header h1 {
  font-family: 'Dancing Script', cursive; /* Unique font */
  color: #fff;
  font-size: 3em;
  text-align: center;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

/* Shiny Background */
.starry-background {
  position: relative;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: hidden;
}

.stars {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: transparent;
  background-repeat: no-repeat;
  background-image: radial-gradient(white, rgba(255, 255, 255, 0) 70%);
  animation: animateStars 50s linear infinite;
}

@keyframes animateStars {
  from { transform: translateY(0); }
  to { transform: translateY(-100%); }
}

/* About Section */
.about-section {
  display: flex;
  justify-content: center;
  padding: 50px 20px;
  background-color: #f7f9fc;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1000px;
  width: 100%;
}

.about-video {
  flex: 1 1 300px;
  margin: 20px;
  max-width: 400px;
}

.about-video video {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.about-text {
  flex: 1 1 300px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-text h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.about-text p {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 30px;
  color: #555;
}

/* Social Links */
.social-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-links h3 {
  margin-bottom: 20px;
  color: #333;
}

/* Button Styles */
.button {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 12px 25px;
  border-radius: 30px;
  transition: background-color 0.3s, transform 0.3s;
  background: linear-gradient(45deg, #0062E6, #33AEFF);
  box-shadow: 0 4px 15px rgba(0, 98, 230, 0.3);
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 98, 230, 0.5);
}

.button img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.button.twitter {
  background: linear-gradient(45deg, #1DA1F2, #0D8BEC);
  box-shadow: 0 4px 15px rgba(29, 161, 242, 0.3);
}

.button.twitter:hover {
  box-shadow: 0 6px 20px rgba(29, 161, 242, 0.5);
}

.button.telegram {
  background: linear-gradient(45deg, #0088cc, #229ED9);
  box-shadow: 0 4px 15px rgba(0, 136, 204, 0.3);
}

.button.telegram:hover {
  box-shadow: 0 6px 20px rgba(0, 136, 204, 0.5);
}

/* Wallet Section */
.wallet-section {
  margin-top: 30px;
  text-align: center;
}

.wallet-section h3 {
  margin-bottom: 10px;
  color: #333;
}

.wallet-address {
  font-family: 'Courier New', Courier, monospace;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  word-break: break-all;
  margin-bottom: 15px;
}

.copy-button {
  background: linear-gradient(45deg, #28a745, #5dd27f);
  color: #fff;
  font-weight: 600;
  padding: 12px 25px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  box-shadow: 0 4px 15px rgba(40, 167, 69, 0.3);
}

.copy-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(40, 167, 69, 0.5);
}

.copy-success {
  margin-top: 10px;
  color: #28a745;
  font-weight: 600;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }

  .logo-overlay h1 {
    font-size: 2em;
  }
}

.logo-section, .about-section {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}