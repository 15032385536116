/* src/Navbar.css */

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 10px 0;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .navbar-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo-container {
    margin-right: 20px;
  }
  
  .nav-logo-image {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    margin: 0 10px;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #333;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .nav-links a:hover {
    background-color: #f0f0f0;
  }
  